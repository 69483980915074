var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-manage" }, [
      _c("div", { attrs: { id: "blog-topImg" } }),
      _c("div", { staticClass: "blog-title-text mt-5 text-center" }, [
        _vm._v("フクベ・新川・大倉・白峰｜"),
        _c("br"),
        _vm._v(" 新しいトポ追加のお知らせ")
      ]),
      _c("div", { staticClass: "container pb-5" }, [
        _c("div", { staticClass: "text-center blog-content-text" }, [
          _c("div", { staticClass: "mt-3" }, [_vm._v("2020/11/20")]),
          _c("div", { staticClass: "mt-3 text-left" }, [
            _c("p"),
            _c("p"),
            _c("p", [_vm._v("こんにちは。ヤマラボ管理人です。")]),
            _c("p"),
            _c("p", [_vm._v("新しいトポ追加のお知らせです！")]),
            _c("p"),
            _c("p", [_vm._v("今回、追加した岩場は")]),
            _c("p"),
            _c("img", {
              staticClass: "blogImg",
              attrs: { src: "/img/blog/20201203/topo_img.png", alt: "" }
            }),
            _c("p"),
            _c("p", [_c("strong", [_vm._v("フクベの全エリア(岐阜県)")])]),
            _c("p", [_c("strong", [_vm._v("新川ボルダー(宮城県)")])]),
            _c("p", [_c("strong", [_vm._v("大倉ボルダー(宮城県)")])]),
            _c("p", [_c("strong", [_vm._v("白峰ボルダー(石川県)")])]),
            _c("p"),
            _c("p", [_vm._v("の、上記4つの岩場です。")]),
            _c("p"),
            _c("p", [
              _vm._v("新川ボルダーは、岩までの道が通行禁止にとなっているため")
            ]),
            _c("p"),
            _c("p", [_vm._v("登攀できない状態です。")]),
            _c("p"),
            _c("p", [_vm._v("お気を付けください。")]),
            _c("p"),
            _c("p", [_vm._v("12月に入り、いよいよ冬本番を迎えますが")]),
            _c("p"),
            _c("p", [_vm._v("安全に、コロナ対策をしっかりして")]),
            _c("p"),
            _c("p", [_vm._v("ボルダリングを楽しみましょう！")]),
            _c("p"),
            _c("p", [_vm._v("引き続き、ヤマラボをよろしくお願いいたします。")]),
            _c("p"),
            _c("p", { staticClass: "text-danger" }, [
              _vm._v("※上記4つの岩場トポは有料プラン限定です")
            ]),
            _c("p", { staticClass: "text-danger" }, [
              _vm._v("※ユーザー登録お済でない方は"),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.yamalab.jp/register_user/send_auth",
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [_vm._v("こちら")]
              ),
              _vm._v("から")
            ]),
            _c("p", { staticClass: "text-danger" }, [
              _vm._v("※有料プランへ切替は"),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.yamalab.jp/user/payment/add_input",
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [_vm._v("こちら")]
              ),
              _vm._v("から")
            ]),
            _c("p"),
            _c("p", [_vm._v("岩とは関係ありませんが")]),
            _c("p"),
            _c("p", [_vm._v("こちら、大倉ボルダー近くにあるお豆腐店")]),
            _c("p"),
            _c("p", [_vm._v("「定義とうふ店」の三角あぶらあげ。")]),
            _c("p"),
            _c("img", {
              staticClass: "blogImg",
              attrs: { src: "/img/blog/20201203/DSC_0729.JPG", alt: "" }
            }),
            _c("p"),
            _c("p", [_vm._v("香ばしい香りが口いっぱいに広がり")]),
            _c("p"),
            _c("p", [_vm._v("とても美味しかったです。")]),
            _c("p"),
            _c("p", [_vm._v("登った後に、美味しいご当地グルメを味わえるのも")]),
            _c("p"),
            _c("p", [_vm._v("ボルダリングの醍醐味ですね。")]),
            _c("p"),
            _c("p", [_vm._v("次回、笠置山のトポを追加予定です。")]),
            _c("p"),
            _c("p", [_vm._v("お楽しみに！")]),
            _c("p")
          ]),
          _c("hr", {
            staticClass: "mt-5",
            attrs: { color: "#CCCCCC", width: "100%", size: "3" }
          }),
          _c("a", { staticClass: "d-inline-block", attrs: { href: "/blog" } }, [
            _c("div", { staticClass: "mt-5 blog-button px-4 py-1" }, [
              _vm._v("ブログ一覧")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }